
<template>
  <div class="newslist bgblue mr10">
    <div class="form">
      <el-form label-width="100px" class="demo-form-inline bgwhite pt10">
        <el-row>
          <el-col :span="4">
            <el-form-item label="标题:">
              <el-input
                v-model="formInline.title"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="消息类型:">
              <el-select v-model="formInline.newstype" placeholder="请选择">
                  <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="状态:">
              <el-select v-model="formInline.state">
                <el-option
                  v-for="item in statelist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建时间:">
              <el-date-picker
                v-model="formInline.creationtime"
                type="datetime"
                placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" style="margin-left: 20px" @click="hook()">
              <i class="el-icon-search"></i>查询</el-button
            >
            <el-button plain> <i class="el-icon-refresh"></i>重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="data">
      <div class="pad10 bgwhite mt10">
        <el-table :data="tableData" border height="calc(100vh - 260px)">
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" class="btn-blue" @click="look(scope.row.id)">查看</el-button>
          </template>

        </el-table-column>
        <el-table-column
          prop="title"
          label="标题"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="summaryContent"
          label="内容摘要"
          width="270"
          align="center"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="newstype" label="消息类型" align="center">
        </el-table-column>
        <el-table-column prop="state" label="状态" align="center" width="100px">
          <template slot-scope="scope">
            <el-tag type="warning">{{scope.row.state ==0?'未读':'已读'  }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="creationtime" label="创建时间" align="center">
        </el-table-column>

      </el-table>
      <div class="pagination">
          <el-pagination
             @size-change="handleSizeChange"
             @current-change="handleCurrentChange"
             :current-page.sync="queryInfo.currPage"
             :page-size="queryInfo.pageSize"
             layout="total, sizes, prev, pager, next, jumper"
             :total="count"
           >
          </el-pagination>
      </div>

      </div>
    </div>
    <el-dialog
      title="消息详情"
      :visible.sync="dialogVisible"
      width="30%">
      <div class="newsdetail">
        <div>消息编号:  &nbsp;&nbsp;&nbsp;<span>{{ newsdetail.agentCode }}</span></div>
        <div>创建人:  &nbsp;&nbsp;&nbsp;<span>{{ newsdetail.creationname }}</span></div>
        <div>创建时间:  &nbsp;&nbsp;&nbsp;<span>{{ newsdetail.creationtim }}</span></div>
        <div>消息状态:  &nbsp;&nbsp;&nbsp;<span>{{ newsdetail.state == 0 ?'未读':'已读' }}</span></div>
        <div>发布时间:  &nbsp;&nbsp;&nbsp;<span>{{ newsdetail.publishtime }}</span></div>
        <div>消息标题:  &nbsp;&nbsp;&nbsp;<span>{{ newsdetail.title }}</span></div>
        <div>消息内容:  &nbsp;&nbsp;&nbsp;<span>{{ newsdetail.summaryContent }}</span></div>
      </div>



    </el-dialog>
  </div>
</template>

<script>
export default {
  methods: {
    look(id){
      this.dialogVisible = true
      this.$http.post('noticemessage/queryOne',{
        id:id
      }).then(res=>{
        this.newsdetail = res.data.data
        console.log(this.newsdetail);
      })

    },
    getnews(){
      this.$http.post('noticemessage/list',this.queryInfo).then(res=>{
        this.tableData = res.data.data.data
        this.count = res.data.data.count
      })
    },
    hook(){
      this.$http.post('noticemessage/queryList',this.formInline).then(res=>{
        this.tableData = res.data.data.data
        this.count = res.data.data.count
      })

    },
     // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getnews()
    },
   // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getnews()
    }
  },
  created(){
    this.getnews()

  },
  data() {
    return {
      tableData: [
        {
          title: "申请客户转移",
          type: "其他",
          content: "客户需要转移到另外一个平台",
          ind: "未读",
          time: "2010-09-09 18:00:00",
        },
        {
          title: "申请客户转移",
          type: "其他",
          content: "客户需要转移到另外一个平台",
          ind: "未读",
          time: "2010-09-09 18:00:00",
        },
      ],
      formInline: {
        title:'',
        state:'',
        newstype:'',
        creationtime:''
      },
      dialogVisible:false,
      queryInfo: {
        pageSize: 50,
        pageCount: 1,
        currPage: 1,
        condition: {}
      },
      newsdetail:{},
      options: [{
          value: '选项1',
          label: "更新通知"
        }, {
          value: '选项2',
          label: "审核驳回"
        }],
        value: '',
      statelist:[{
          value: '未读',
          label: '未读'
        }, {
          value: '已读',
          label: '已读'
        }],
        count:''

    };
  },
};
</script>

<style lang="less" scoped>
.newslist {
  padding: 10px;
  .el-form-item__label{
    font-size: 13px;
  }
  .el-table{
    margin-top: 10px;
  }
  .el-table--border th{
    padding:2px 0
  }
  .el-form .el-row .el-col-4 {
    width: 19.5%;
    .form {
      background-color: #fff;
      border-radius: 10px;
      padding: 10px;
    }
    .data {
      background-color: #fff;
      border-radius: 10px;
      padding: 10px;
      margin-top: 10px;
    }
  }
}
.newsdetail {
  border-top: 1px solid rgba(215, 215, 215, 0.44);
  padding: 10px;
  color: #555;
  div {
    margin-bottom: 5px;
  }
  span {
    color: #333;
  }
}
/deep/ .el-dialog {
  border-radius: 10px;
}
/deep/ .el-dialog__body {
  padding-top: 5px;
}
</style>
